/**
 * ----------------------------------------
 * animation rotate-scale-up
 * ----------------------------------------
 */
@-webkit-keyframes rotate-scale-up {
    0% {
        -webkit-transform: scale(1) rotateZ(0);
        transform: scale(1) rotateZ(0);
    }
    50% {
        -webkit-transform: scale(2) rotateZ(180deg);
        transform: scale(2) rotateZ(180deg);
    }
    100% {
        -webkit-transform: scale(1) rotateZ(360deg);
        transform: scale(1) rotateZ(360deg);
    }
}
@keyframes rotate-scale-up {
    0% {
        -webkit-transform: scale(1) rotateZ(0);
        transform: scale(1) rotateZ(0);
    }
    50% {
        -webkit-transform: scale(2) rotateZ(180deg);
        transform: scale(2) rotateZ(180deg);
    }
    100% {
        -webkit-transform: scale(1) rotateZ(360deg);
        transform: scale(1) rotateZ(360deg);
    }
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes fadeInRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        
        transform: translateX(0rem);
    }
}

@keyframes fadeInOut {
    0% { 
        opacity: 0; 
    }
    
    50% {
        opacity: 1; 
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeInOut {
    0% { 
        opacity: 0; 
    }

    50% {
        opacity: 1; 
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

  