#series-form {
    input {
        font-size: 2.4rem;
        width: 100%;
        padding: 0 1rem;

        &:focus {
            outline: none;
        }
    }

    .react-tabs__tab-list {
        margin: 0;
        border-bottom: none;
    }

    .react-tabs__tab-panel,
    .react-tabs__tab {
        background-color: $white;
    }

    .react-tabs__tab {
        border: 1px solid #aaa;

        &.react-tabs__tab--selected {
            border-bottom: 1px solid $white;
        }

        &:focus {
            outline: none;
        }
    }

    .react-tabs__tab-panel  {
        padding: 1.5rem;
        border: 1px solid #aaa;
    }

    .react-tabs__tab {
        margin-left: 1rem; 
        border-radius: 0;
        font-family: myriad-pro, sans-serif;
        font-weight: bold;
        font-size: 1.5rem;
    }

    .description-container {
        textarea,
        div.textbox,
        .flatpickr-input,
        .season-container {
            height: 6rem;
            width: 100%;   
            border: 1px solid #aaa;
            border-top: none;
            resize: none;
            padding: 1rem;

            &.description {
                height: 15rem;
            }

            &:focus {
                outline: none;
            }
        }

        textarea {
            font-size: 1.5rem;
        }

        div.textbox {
            overflow-y: scroll;
            height: 12rem;

            span.react-tagsinput-tag {
                background-color: $green;
                color: $white;
                border: none;
            }

            div.react-tagsinput.react-tagsinput--focused {
                border-color: #aaa;
                color: $black;
            }
            
            input.react-tagsinput-input {
                font-size: 1.5rem;
                
            }
        }
        
        svg {
           right: 1.5rem;
        }

        .react-sanfona-item-title,
        label {
            position: relative;
            background-color: $lightgrey;
            color: #7a7a7a;
            width: 100%;
            font-size: 1.5rem;
            border: 1px solid #aaa;
            padding: .5rem 1.5rem;
        }

        .react-sanfona-item-title {
            cursor: pointer;
        }

        .accordion-item {
            position: relative;
            background-color: $white;
            padding-bottom: 2.5rem;
        }

        .accordion-icon {
            position: absolute;
            top: .5rem;
            right: 2rem;
        }
      
      
        .flatpickr-input {
            font-size: 1.5rem;
            border-radius: 0;
            background-color: $white;
            box-shadow: none;
        }

        .season {
            &-container {
                height: 100%;
                padding: 1.5rem;
            }

            &-message {
                border: 2px dashed #aaa;
                font-size: 1.5rem;
            }

            &-edit {
                position: relative;
                border: 1px solid #aaa;
                margin-bottom: 1.5rem;

                p {
                    font-size: 1.5rem;
                    white-space: normal;

                    span {
                        color: $green;
                        word-break: break-all;
                    }
                }

                button {
                    position: absolute;
                    top: 1.5rem;
                    right: 1.5rem;
                    width: auto;
                    padding: .5rem 2rem; 
                    border-radius: 3px;
                }

                .row {
                    @media screen and (max-width: 93em) {
                        flex-column: row;
                    }
                }

                .col-xl-3,
                .col-xl-9 {
                    // @media screen and (max-width: 118em) {
                    //     flex: 0 0 100%;
                    //     max-width: %;
                    // }

                    @media screen and (max-width: 87em){
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                    align-self: center;
                }
            }
        }
    }

    //React Select Input
    .css-vj8t7z,
    .css-2o5izw {
        min-height: 6rem;
        border-radius: 0;
        box-shadow: none;
        border: 1px solid #aaa;
        border-top: 0;
    }

    .css-1alnv5e:hover {
        background: $green;
        color: $white;
    }

    .css-wqgs6e { 
        background: rgba($green, .5);

        &:hover {
            background: rgba($green, .5);
        }
    }
}