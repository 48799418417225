*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6,
ul, hr, p, label {
    margin: 0;
}

p,span ,td, b, label {
    font-size: 1.5rem;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

input {
    box-shadow: none;
    outline: none;

    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
    }
}

button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    
    &:focus {
        outline: none;
        box-shadow: none;
    }
}