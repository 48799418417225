#series-section {
    padding: .5rem .5rem 3rem .5rem;

    .series-searchbar {
        position: relative;

        @media screen and (max-width: 71.25em) {
            width: 100%;
            margin-top: 2rem;
        }
    }

    .series-container {
        @media screen and (max-width: 71.25em) {
            flex-direction: column;
        }

        &-adding input {
            font-size: 2.4rem;
            padding: 2rem 1rem;
        }
    }

    h1 {
        font-size: 3rem;
        font-family: 'SF Pro Display', 'Open Sans', sans-serif;
    }

    svg.icon-search {
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
    }


}