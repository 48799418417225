#accounts {
    ul li {
        background-color: $white;
        border-bottom: 1px solid $lightgrey;
        padding: 0.5rem 1rem;

        &:hover {
            background-color: $grey;
            opacity: 0.7;
            cursor: pointer;
            color: $white;
        }
    }
}

#accounts-component {
    max-width: 50rem;
}