#sidedrawer-left {
    background-color: $purple;
    color: $white;
    display: none;
    font-size: $default-font-size;
    min-width: 20rem;
    transition: transform 0.3s ease-out;

    @media screen and (min-width: 62em) {
        display: flex;
        justify-content: center;
    }

    .open-sidedrawer {
        transform: translateX(0);
      }
      
    .close-sidedrawer {
        transform: translateX(-100%);
    }

    section {
        width: 100%;
    }

    ul {
        h3 {
            font-size: $default-font-size;
            margin: 0;
            background-color: #70a2b8;     
        }

        li {
            cursor: pointer;
            font-size: 1.5rem;
            line-height: 1.2;
            list-style: none;
            color: rgba(255, 255, 255, 0.502);

            &:hover {
                color: $white;
            }
        }
    }
}

#sidedrawer-right {
    background-color: $white;
    transition: all .5s;
    min-width: 33rem;
    max-width: 33rem;
    display: none;

    &.active {
        display: flex;
        animation: fadeInRight .5s ease-in;
        
        @media screen and (max-width: 62em) {
            display: none;
        }

        &.mobile-active {
            display: flex;
            position: absolute;
            right: 0;
            min-height: 100%;

            @media screen and (min-width: 62em) {
                position: relative;
            }

            @media screen and (max-width: 36em) {
                min-width: 100%;
                max-width: 100%;
            }  
        }
    }

    &.inactive {
        display: none;
    }

    .dropdown-container {
        cursor: pointer;
    }

    .section-status,
    .section-thumbnail,
    .section-categories {
        h3, p, button {
            font-size: 1.5rem;

            &.bold {
                font-weight: 600;
            } 
        }

        p, button {
            color: $green;
        }

        p.pointer {
            cursor: pointer;
        }
        
        &.draft {
            cursor: initial;
        }
    }

    .section-categories .checkbox-custom {
        &-container {
            position: relative;
            border: 1px solid rgba(0, 0, 0, 0.1);
        }

        &-label {
            display: block;
            position: relative;
            cursor: pointer;
            margin-bottom: 1rem;
            padding-left: 3rem;
            font-size: 1.5rem;

            &:last-child {
                margin-bottom: 0;
            }

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked ~ .checkmark:after {
                    display: block;
                }
            }

            .checkmark {
                position: absolute;
                top: 50%;
                left: 0;
                height: 1.5rem;
                width: 1.5rem;
                border: 1px solid #ddd;
                background-color: transparent;
                transform: translateY(-50%);

                &.featured {
                   left: unset;
                   right: 0;
                }
                
                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    left: 4px;
                    top: 1px;
                    width: 5px;
                    height: 10px;
                    border: solid $green;
                    border-width: 0 3px 3px 0;
                    transform: rotate(45deg);
                }
            }  
        }   
    }

    .img-container {
        position: relative;

        .edit-container {
            position: absolute;
            top: 1rem;
            right: 1rem;

            button {
                height: 2.5rem;
                width: 2.5rem;
                background-color: rgb(116, 116, 116);
                border-radius: 50%;

                &:first-child {
                    margin-right: .5rem; 
                }
            }
        }
    }
}
  
