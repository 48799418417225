#addepisode {
    padding-bottom: 3rem;

    input {
        width: 100%;
    }

    h2 {
        font-size: 1.8rem;
    }

    button {
        width: 2.5rem;
        height: 2.5rem;

        &.upload-aws,
        &.add-episode {
            height: auto;
            width: auto;
            min-width: 13rem; 

            .updating {
                animation: 1s ease-in-out 0s infinite fadeInOut
            }
        }

        &.filestack {
            color: rgb(86, 86, 86);
            width: 9rem;
            display: inline-block;
            font-size: 1.5rem;
        }
    }

    .checkbox-dailymotion {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1rem;

        h3 {
            margin-left: .5rem;
        }

        div {
            display: flex;
            align-items: center;
            height: 100%;
            width: 1rem;
        }
    }

    .input-container {
        border: 1px solid #aaa;

        .icon-wrapper {
            background-color: rgb(234, 234, 234);
        }
        
        .uploaded-url {
            word-break: break-all;
        }

        .img-container {
            position: relative;
            width: 50%;

            .edit-container {
                position: absolute;
                top: 1rem;
                right: 1rem;

                button {
                    height: 2.5rem;
                    width: 2.5rem;
                    background-color: rgb(116, 116, 116);
                    border-radius: 50%;

                    &:first-child {
                        margin-right: .5rem; 
                    }
                }
            }
        }

        .input-file-container {
            position: relative;
            overflow: hidden;
            display: inline-block;
           
            
            button.btn-file {
                font-size: 1.5rem;
                width: 9rem;
            }

            input[type="file"] {
                width: 9rem;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
            }

            input {
                height: 2.5rem;
                cursor: pointer;
            }
        }

        span, p {
            font-size: 1.5rem;
        }

        p {
            font-weight: bold;            
        }

        span {
            font-weight: normal;
            &.filename {
                color: #62a3b7;
            }
        }

        h3 {
            font-size: 1.5rem;
            font-weight: 600;
        }

        input {
            &.input-title,
            &.flatpickr-input {
                border: 1px solid #aaa;
                font-size: 1.5rem;
            }

            &.flatpickr-input {
                background-color: $white;
                border-radius: 0;
                box-shadow: none;

                &.form-control {
                    padding: 2rem 1rem;
                }
            }
        }

        .css-vj8t7z,
        .css-2o5izw {
            border-radius: 0;
            box-shadow: none;
            border: 1px solid #aaa;
        }

        .css-15k3avv,
        
        .css-11unzgr,
        .css-z5z6cw,
        .css-v73v8k {
            background: $white;
        }

        .css-wqgs6e { 
            background: rgba($green, .5);

            &:hover {
                background: rgba($green, .5);
            }
        }

        .img-container {
            @media only screen and (max-width: 36em) { 
                width: 100%;
            }
        }
        
        img {
            @media only screen and (max-width: 36em) { 
                width: 100%;
            }
        }
    }
}

