.header {
    background-color: $purple;
    position: relative;
    padding: 1.5rem;

    &-nav-item {
        font-size: 1.5rem;
        color: $white;
        cursor: pointer;
        
        @media screen and (min-width: 62em){
            display: none;
        }
    }

    button {
        background-color: $green;
    }

    &-account {         
        &-desktop {
            display: flex;

            @media screen and (max-width: 62em){
                display: none;
            }
        }

        &-mobile {
            @media screen and (min-width: 62em){
                display: none;
            }
        }

        @media screen and (max-width: 62em){
            margin-bottom: 1rem;
        }

        &-initials {
            padding-left: 4px;
            font-size: 1.7rem;
            letter-spacing: 3px;
            width: 4.6rem;
            height: 4.6rem;
            color: $white;
            background-color: $pink;
            border-radius: 50%;
        }

        a {
            text-decoration: none;
        }
        
        &-logout {
            color: $grey;
            font-size: 1.3rem;
            line-height: 1.5;
    
            &:visited,
            &:active {
                color: $grey;  
            }
        }

        h3 {
            margin: 0;
            font-size: 1.4rem;
            color: $white;
        }
    }
} 
  