#banner {

    section {
        background-color: $white;
        padding: 1.5rem;

        .react-sanfona .react-sanfona-item {
            margin-bottom: 1.5rem;
            position: relative;
            

            .accordion-icon {
                position: absolute;
                cursor: default;
                top: .5rem;
                right: 2rem;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .banner {
                &-container,
                &-upload,
                &-url {
                    border: 1px solid #aaa;
                    padding: 1.5rem;

                    label {
                        font-family: myriad-pro, sans-serif;
                        font-weight: bold;
                        font-size: 1.5rem;
                        margin-right: 1rem;
                    }
                    
                    p {
                        font-size: 1.5rem;
                    }
                }

                

                &-container {
                    border: 1px solid #aaa;
                    border-top: none;
                }

                &-upload span,
                &-url span {
                    font-size: 1.5rem;
                }

                &-url span{
                    color: #6ea2b7;
                    word-break: break-all;
                }

                &-edit-container {
                    position: relative;
                    
                    img {
                        width: 100%;
                        max-height: 35.4rem ;
                    }

                    button {
                        top: 1.5rem;
                        position: absolute;
                        height: 2.5rem;
                        width: 2.5rem;
                        background-color: #747474;
                        border-radius: 50%;

                        @media screen and (max-width: 36em) {
                            top: -3rem;
                        }

                        &.delete {
                            right: 1.5rem;

                            @media screen and (max-width: 36em) {
                                right: 0;
                            }
                        }

                        &.edit {
                            right: 4.5rem;

                            @media screen and (max-width: 36em) {
                                right: 3rem;
                            }
                        }
                    }
                }
               
            }

            .react-sanfona-item-title {
                cursor: pointer;
                position: relative;
                background-color: $lightgrey;
                color: #7a7a7a;
                width: 100%;
                font-size: 1.5rem;
                border: 1px solid #aaa;
                padding: .5rem 1.5rem;
            }
        }
    } 
}