html {
    box-sizing: border-box;
    font-size: 62.5%; // 1rem = 10px;
}

body {
    background-color: $lightgrey;

    .modal {
        padding: 0;

        &-header {
            background-color: #70a2b8;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            color: $white;
    
            span {
                color: $white;
                font-size: 2rem;
                margin-right: .75rem;
            }
        }

        &-dialog {
            margin: 0;
        }

        &-content {
            border: none;
        }

        &-body {
            label {
                font-size: 1.6rem;
            }
        }
    }

    .modal-backdrop {
        z-index: 200;
    }
}

html,
body {
    height: 100%;
}
