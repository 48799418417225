.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
  
.modal {
    display: inline-block;
    position: absolute;
    z-index: 500;
    background-color: $white;
    max-height: 60%;
    overflow-y: scroll;
    width: 40%;
    padding: 3rem 3rem 0 3rem;
    left: 30%;
    top: 15%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;

    @media only screen and (max-width: 62em) { 
        width: 70%;
        left: 15%;
    };

    &.uploaded {
        max-height: 62.5rem;
    }

    @media only screen and (max-width: 36em) { 
        width: 90%;
        left: 5%;
    };
}

.modal-show {
    transform: translateY(0);
    opacity: 1
}

.modal-hide {
    transform: translateY(-100vh);
    opacity: 0
}

.modal-react {
    padding: 3rem 3rem 0 3rem; 
    position: absolute;
    top: 50%;               
    left: 50%;              
    right: auto;                
    bottom: auto;
    // margin-right: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;

    width: 68rem;
    height: auto;
    max-height: calc(100vh - 20rem);
    overflow-y: scroll;      

    @media screen and (max-width: 47em ){
        width: 90%;
    }

    @media screen and (max-height: 50em ) {
        max-height: calc(100vh - 5rem);
    }
}

.modal-overlay-categories {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}



