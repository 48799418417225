@charset 'UTF-8';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');
@import url("https://use.typekit.net/uws4pll.css");

@font-face {
    font-family: "SF Pro Display";
    src: url('/assets/fonts/sfpd.otf') format('otf');
}

// 1. Configuration and helpers
@import 'abstracts/mixins',
'abstracts/variables';

// 2. Base stuff
@import 'base/animations',
'base/base',
'base/typography';

// 3. Layout-related sections
@import 'layout/layout',
'layout/header',
'layout/sidedrawer';

// 4. Components
@import 'components/accounts',
'components/analytics-component',
'components/paginate',
'components/button',
'components/banner',
'components/inputs',
'components/modal',
'components/categories-form',
'components/categories-list',
'components/forms',
'components/series-component',
'components/series-list',
'components/series-form',
'components/podcasts',
'components/episode-add',
'components/sortable-list';