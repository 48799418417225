#layout {
    height: 100%;
   

    .layout-main {
        height: 100%;
        position: relative;
    }

    main {
        position: relative;
        padding: 3rem;
        width: 100%;
        min-height: 100vh;
    }
}

#login-google {
    a {
        cursor: pointer;
        font-size: 2rem;
        color: $black;
        text-decoration: none;
        background-color: $white;
        padding: 1.5rem 2rem;
        border-radius: 5px;
    }
}

#home {
    .toast-body,
    .toast {
        opacity: 1;
    }

    .toast {
        font-size: 1.6rem;
        color: $white;
        background-color: $green;
        text-align: center;
    }
}

#callback {
    position: relative;

    .overlay {
        background-color: rgba(#000, .5);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &-content {
            background-color: $white;
            padding: 2rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            p {
                font-size:  1.6rem;
            }
        }
    }
}


