#react-paginate ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

#react-paginate li {
    display: inline-block;
    background-color: $white;
    padding: 1rem 0;
    margin: 0 .5rem;
   
    a {
        font-size: 1.6rem;
        color: $black;
        padding: 1rem;

        &:hover {
            cursor: pointer;
        }

        outline: none;
    }

    &.disabled {
        visibility: hidden;
    }
    
    &.active {
        background-color: $pink;

        a {
            color: $white;
        }
    }
}