.input-rounded-white {
    background-color: $white;
    border: none;
    border-radius: .5rem;
    font-size: 1.5rem;
    padding: 1rem 2rem;
    width: 30rem;

    @media screen and (max-width: 71.25em) {
        width: 100%;
    }

    &:focus {
        outline: none;
    }
}

.error {
    margin-top: .5rem;
    color: red;
    animation: fadein 1s;
}

#login {
    form {
        max-width: 35rem;
    }

    input {
        font-size: 1.5rem;
    }
}
