.sortable-banner-list {
    background-color: #f3f3f3;
    border: 1px solid #efefef;
    outline: none;
    width: 100%;


    &.podcast {
        width: 100%;
        max-width: 50rem;
    }

    &-item {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        height: 3rem;
        background-color: $white;
        border-bottom: 1px solid #efefef;
        padding: 0 1rem;

        &.podcast {
            font-size: 1.4rem;
            min-height: 4.5rem;
        }
    }
}