#podcast {
    .sortable-container {
        min-width: 50rem;
    }

    &-episodes {
        li.editable {
            font-size: 1.6rem;
            background-color: $white;
            line-height: 3rem;

            &:hover {
                cursor: pointer;
            }

            &.active {
                line-height: 6rem;
                background-color: $pink;
                color: $white;
            }
        }

        .episode-container {
            
            li.subtitle {
                font-size: 1.6rem;
                background-color: $white;
                border-radius: 1.5rem;
                border: 1px solid rgb(202, 197, 197);
                max-width: 18.5rem;

                p {
                    &:hover {
                        cursor: pointer;
                        color: $pink;
                    }
                }

                button {
                    width: 2rem;

                    &:hover {
                        color: $pink;
                    }
                }
            }

            form {
                font-size: 1.5rem;

                label {
                    width: 20rem;
                }

                button.contentImage {
                    background-color: $green;
                    color: $white;
                    padding: .25rem 1rem;
                   
                }

                .img-container {
                    max-width: 35rem;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    &-categories {
        ul {
            min-width: 50rem;

            li {
                border-radius: 1.5rem;
                border: 1px solid rgb(202, 197, 197);
                background-color: white;

                &:hover {
                    cursor: pointer;
                }

                &.active {
                    background-color: $pink;
                    color: $white;
                }

                &.non-active {
                    background-color: $white;
                }
            }
        }
        
       
    }

    &-list {
        ul {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
            grid-auto-rows: 1fr;

           
        }

        ul::before {
          
                content: '';
                width: 0;
                padding-bottom: 100%;
                grid-row: 1 / 1;
                grid-column: 1 / 1;
            
          
        }

        ul > *:first-child {
            
                grid-row: 1 / 1;
                grid-column: 1 / 1;
            
            
          }

        li {
            width: 100%;

            img {
                width: 100%;
            }

            border: 5px transparent solid;
            cursor: pointer;
        }
    }


    &-form {
        label, .filestack-podcast {
            background-color: $lightgrey;
            color: #7a7a7a;
            width: 100%;
            font-size: 1.5rem;
            border: 1px solid #aaa;
            padding: .5rem 1.5rem;
        }

        h3 {
            color: #7a7a7a;
        }

        input.podcast-input, textarea,  .subtitles-container {
            height: 6rem;
            font-size: 1.6rem;
            padding: .5rem 1rem;
            border: none;
            border: 1px solid #aaa;
            border-top: none;
            margin-bottom: 1.5rem;
            width: 100%;

            &.title {
                height: 4rem;
                font-size: 2.4rem;
                border-top: 1px solid #aaa;
            }

            &:focus {
                outline: none;
            }
        }

        .subtitles-container{
            background-color:  $white;
        }

        .css-bg1rzq-control {
            border-radius: 0;
            border-top: none;
        }

        .css-1szy77t-control:hover,
        .css-1szy77t-control {
            border-radius: 0;
        }

        .featured {
            input {
                width: 2rem;
                height: 4rem;
                margin-bottom: 0;

                &:hover {
                    cursor: pointer;
                }
            }
    
            p {
                color: #7a7a7a;
            }
        } 
        

        textarea {
            border: 1px solid #aaa;
            border-top: none;
            resize: none;
            
            height: 15rem;
        }

        .filestack-podcast {
            text-align: center;
            width: 17rem;
        }

        img {
            width: 100%;
            max-width: 30rem;
        }

        .img-container {
            position: relative;
            width: 100%;
            max-width: 30rem;

            &.thumbnail {
                max-width: 20rem;
            }

            &.featured {
                max-width: 27rem;
            }

            .edit-container {
                position: absolute;
                top: 1rem;
                right: 1rem;

                button {
                    height: 2.5rem;
                    width: 2.5rem;
                    background-color: rgb(116, 116, 116);
                    border-radius: 50%;

                    &:first-child {
                        margin-right: .5rem; 
                    }
                }
            }
        }
    }
}

#podcast-socials {
    div {
        margin-right: 1rem;
        margin-bottom: 1rem;
    }

    video {
        margin: 0;
        width: 100%;
        max-width: 36rem;
        max-height: 64rem;
    }
}