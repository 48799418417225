.btn-rounded-pink {
    border-radius: .5rem;
    border: none;
    background-color: $pink;
    color: $white;
    font-family: myriad-pro, sans-serif;
    font-size: 1.5rem;
    padding: 1rem 2rem;
    width: 13rem;
    text-transform: uppercase;
   
    &.grey {
        background-color: lightgrey;
    }

    &.sm {
        font-size: 1.4rem;
    }

    &.fade-in {
        animation: fadein 1s;
    }

    &.login {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.loading {
        animation: fadeInOut 1s infinite;
    }

    .loading {
        animation: fadeInOut 1s infinite;
    }

    &:hover {
        text-decoration: none;
        color: $white;
    }

    &:active,
    &:visited {
        color: $white;
    }
}

.btn-delete {
    cursor: pointer;
    color: red;
    text-transform: uppercase;
    font-size: 1.5rem;

    @media screen and (max-width: 36em){
        margin-top: 1.5rem;
    }
}

.btn-underlined-red {
    text-decoration: underline;
    font-size: 1.5rem;
    color: red;
}

.btn-rounded-grey {
    border-radius: .5rem;
    background-color: #cccccc;
    color: rgb(86, 86, 86);
    width: 9rem;
    display: inline-block;
    font-size: 1.5rem;

    &.video {
        margin-right: 1.5rem;

        @media screen and (max-width: 353px){
            margin-bottom: 1.5rem;
        }

        &.highlight {
            border: 2px solid blue;
        }
    }

    &.banner {
        width: 11rem;
    }
}

