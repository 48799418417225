#analytics {
    .flatpickr-input {
        width: 100%;
        max-width: 30rem;
    }

    form input {

        &active,
        &:focus {
            outline: none !important;
        }
    }
}

#analyticsContainer {
    max-width: 992px;

    tbody tr td a.url {
        color: $black;
        text-decoration: none;
    }

    section div table thead tr {
        th {
            font-size: 1rem;


            &.focused {
                background-color: $pink;
                color: $white;
            }
        }
    }
}