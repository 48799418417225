#series-list {
    .row.no-gutters {
        margin-right: 0;
        margin-left: 0;
      
        > .col,
        > [class*="col-"] {
          padding-right: .5rem;
          padding-left: .5rem;
          padding-bottom: 1rem;
        }

        li img {
            cursor: pointer;
        }
    }
} 